<template>
  <a-modal
    v-model="isShow"
    :mask-closable="false"
    :title="title"
    :width="1200"
    cancel-text="关闭"
    centered
    ok-text="刷新"
    @ok="handleReset"
  >
    <div class="search">
      <a-space>
        <a-range-picker
          :show-time="{ format: 'HH:mm:ss' }"
          :value="intervalTime"
          @change="handleChangeIntervalTimePicker"
        />
        <a-button type="primary" @click="submitSearch">
          搜索
        </a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      size="middle"
    />

    <pagination
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      :total-count="pagination.total_count"
      @change="fetchData"
    />
  </a-modal>
</template>

<script>
import { findAttributeHistoricalDataList } from '@/api/attribute'
import Pagination from '@/components/Pagination/index'

export default {
  name: 'History',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    gatewayId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    Pagination
  },
  data() {
    return {
      title: '',
      isSpan: undefined,
      spanTitle: undefined,
      loading: true,
      data: [],
      startAt: undefined,
      endAt: undefined,
      // 间隔时间
      intervalTime: undefined,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: 'A相',
          dataIndex: 'a_phase.data',
          align: 'center',
          customRender: (value, row, index) => {
            if (row.is_span) {
              return {
                children: <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>,
                attrs: {
                  colSpan: 4
                }
              }
            }
            return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
          }
        },
        {
          title: 'B相',
          dataIndex: 'b_phase.data',
          align: 'center',
          scopedSlots: { customRender: 'b_phase' },
          customRender: (value, row, index) => {
            if (row.is_span) {
              return {
                children: <span style={{ color: row.b_phase.is_alarm ? row.b_phase.alarm_color : '' }}>{value}</span>,
                attrs: {
                  colSpan: 0
                }
              }
            }
            return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
          }
        },
        {
          title: 'C相',
          dataIndex: 'c_phase.data',
          align: 'center',
          scopedSlots: { customRender: 'c_phase' },
          customRender: (value, row, index) => {
            if (row.is_span) {
              return {
                children: <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>,
                attrs: {
                  colSpan: 0
                }
              }
            }
            return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
          }
        },
        {
          title: '更新时间',
          align: 'center',
          dataIndex: 'collected_at'
        }
      ]
    }
  },
  methods: {
    // 监听间隔时间日期选择
    handleChangeIntervalTimePicker(data, dateString) {
      this.intervalTime = data
      if (dateString[0] !== '' || dateString[1] !== '') {
        this.startAt = data[0]
        this.endAt = data[1]
      }
    },

    fetchData() {
      this.loading = true
      findAttributeHistoricalDataList(Object.assign({
        attribute_group_id: this.id,
        record_type: 'gateway',
        record_id: this.gatewayId
      }, this.query)).then(res => {
        if (res.code === 0) {
          this.title = res.data.title
          this.data = res.data.data
          this.pagination = res.pagination
          this.isSpan = res.data.is_span
          this.spanTitle = res.data.span_title

          // 判断是否合并
          if (this.isSpan && this.columns.length >= 4) {
            this.columns.splice(1, 2)
            this.columns[0].title = this.spanTitle
          }

          // 判断是否显示总列
          if (this.data.length !== 0 && this.data[0].total_phase.data !== null && this.columns[3].dataIndex !== 'total_phase.data') {
            this.columns.splice(3, 0, {
              title: '总',
              dataIndex: 'total_phase.data',
              align: 'center',
              scopedSlots: { customRender: 'total_phase' },
              customRender: (value, row, index) => {
                if (row.is_span) {
                  return {
                    children: <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}
                    >{value}</span>,
                    attrs: {
                      colSpan: 0
                    }
                  }
                }
                return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
              }
            })
          }
        }
        this.loading = false
      })
    },

    handleReset() {
      this.intervalTime = undefined
      this.startAt = undefined
      this.endAt = undefined
      this.query = Object.assign({}, this.$options.data().query)

      this.fetchData()
    },

    submitSearch() {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        {
          start_at: this.startAt,
          end_at: this.endAt
        })
      this.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  display: flex;
  margin-bottom: 20px;
}
</style>
