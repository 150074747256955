<template>
  <a-modal
    v-model="isShow"
    :mask-closable="false"
    :title="title"
    :width="1200"
    cancel-text="关闭"
    centered
    ok-text="刷新"
    @ok="handleReset"
  >
    <a-spin
      :spinning="loading"
      class="se-spin-stretch"
    >
      <div class="search">
        <a-space>
          <a-range-picker
            :show-time="{ format: 'HH:mm:ss' }"
            :value="intervalTime"
            @change="handleChangeIntervalTimePicker"
          />
          <a-button type="primary" @click="submitSearch">
            搜索
          </a-button>
        </a-space>
      </div>

      <div ref="thread" :style="{'height':!isEmpty ? '250px' : 0}" />
      <a-empty v-if="isEmpty" />
    </a-spin>
  </a-modal>
</template>

<script>

import { findAttributeHistoricalDataThread } from '@/api/attribute'

export default {
  name: 'Thread',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    gatewayId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      title: '',
      data: [],
      loading: true,
      chart: null,
      startAt: undefined,
      endAt: undefined,
      // 间隔时间
      intervalTime: undefined,
      isEmpty: true
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    chartOption() {
      return {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        grid: {
          x: 70,
          y: 0,
          x2: 70,
          y2: 30
        },
        series: []
      }
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findAttributeHistoricalDataThread(Object.assign({
        attribute_group_id: this.id,
        record_type: 'gateway',
        record_id: this.gatewayId
      }, this.query)).then(res => {
        if (res.code === 0) {
          this.title = res.data.title
          this.data = res.data
          for (let i = 0; i < this.data.statistics.length; i++) {
            if (this.$lodash.map(this.data.statistics[i].data, 'value').length !== 0) {
              this.isEmpty = false
              this.$nextTick(() => {
                this.drawChart()
              })
              break
            } else {
              this.isEmpty = true
              if (this.chart !== null) {
                this.chart.clear()
              }
            }
          }
        }
        this.loading = false
      })
    },

    setSeries(i) {
      this.chartOption.xAxis.data = this.$lodash.map(this.data.statistics[i].data, 'name')
      this.chartOption.series.push({
        type: 'line',
        data: this.$lodash.map(this.data.statistics[i].data, 'value'),
        name: this.data.statistics[i].type
      })
    },

    drawChart() {
      this.chartOption.series = []
      this.chart ||= this.$echarts.init(this.$refs.thread, 'echarts_dark')
      if (this.data.statistics !== null) {
        for (let i = 0; i < this.data.statistics.length; i++) {
          this.setSeries(i)
        }
      }
      this.chart.setOption(this.chartOption, true)
    },

    // 监听间隔时间日期选择
    handleChangeIntervalTimePicker(data, dateString) {
      this.intervalTime = data
      if (dateString[0] !== '' || dateString[1] !== '') {
        this.startAt = data[0]
        this.endAt = data[1]
      }
    },

    handleReset() {
      this.intervalTime = undefined
      this.startAt = undefined
      this.endAt = undefined
      this.query = Object.assign({}, this.$options.data().query)

      this.fetchData()
    },

    submitSearch() {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        {
          start_at: this.startAt,
          end_at: this.endAt
        })
      this.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  display: flex;
  margin-bottom: 20px;
}
</style>
