<template>
  <div class="box">
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      bordered
      row-key="id"
      size="middle"
    >
      <span slot="a_phase" slot-scope="text,record">
        <span>{{ text }}{{ record }}</span>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showHistoryModal(record.id)">历史数据</a>
          <a v-if="record.is_trend" @click="showThreadModal(record.id)">趋势</a>
        </a-space>
      </span>
    </a-table>

    <!-- 历史数据 模态框 -->
    <history
      v-if="isShowHistoryModal"
      :id="editingId"
      :gateway-id="id"
      :visible.sync="isShowHistoryModal"
    />

    <!-- 趋势 模态框 -->
    <tread
      v-if="isShowThreadModal"
      :id="editingId"
      :gateway-id="id"
      :visible.sync="isShowThreadModal"
    />
  </div>
</template>

<script>
import History from '@/views/gateway/details/modules/History'
import Tread from '@/views/gateway/details/modules/Tread'
import { findAttributesList } from '@/api/attribute'

export default {
  name: 'AttributeList',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    Tread,
    History
  },
  data() {
    return {
      data: [],
      loading: true,
      // 是否显示历史数据弹窗
      isShowHistoryModal: false,
      // 是否显示趋势弹窗
      isShowThreadModal: false,
      editingId: 0
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '参数名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'A相',
          dataIndex: 'a_phase.data',
          align: 'center',
          customRender: (value, row, index) => {
            if (row.is_span) {
              return {
                children: <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>,
                attrs: {
                  colSpan: 4
                }
              }
            }
            return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
          }
        },
        {
          title: 'B相',
          dataIndex: 'b_phase.data',
          align: 'center',
          scopedSlots: { customRender: 'b_phase' },
          customRender: (value, row, index) => {
            if (row.is_span) {
              return {
                children: <span style={{ color: row.b_phase.is_alarm ? row.b_phase.alarm_color : '' }}>{value}</span>,
                attrs: {
                  colSpan: 0
                }
              }
            }
            return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
          }
        },
        {
          title: 'C相',
          dataIndex: 'c_phase.data',
          align: 'center',
          scopedSlots: { customRender: 'c_phase' },
          customRender: (value, row, index) => {
            if (row.is_span) {
              return {
                children: <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>,
                attrs: {
                  colSpan: 0
                }
              }
            }
            return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
          }
        },
        {
          title: '总',
          dataIndex: 'total_phase.data',
          align: 'center',
          scopedSlots: { customRender: 'total_phase' },
          customRender: (value, row, index) => {
            if (row.is_span) {
              return {
                children: <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>,
                attrs: {
                  colSpan: 0
                }
              }
            }
            return <span style={{ color: row.a_phase.is_alarm ? row.a_phase.alarm_color : '' }}>{value}</span>
          }
        },
        {
          title: '更新时间',
          dataIndex: 'collected_at',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          align: 'center',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findAttributesList(Object.assign({
        record_type: 'gateway',
        record_id: this.id
      })).then(res => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    showHistoryModal(id) {
      this.editingId = id
      this.isShowHistoryModal = true
    },

    showThreadModal(id) {
      this.editingId = id
      this.isShowThreadModal = true
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 100%;
  padding: 10px 5px 10px 5px;
}
</style>
